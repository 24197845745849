<template>
  <List el="xsm" style="padding: 32px">
    <!-- <Text el="h2">{{ $route.params.id }}</Text> -->
    <List el="column-between" class="v-center">
      <Text el="h3" v-if="learning">{{ learning.label }}</Text>
      <!-- <Text el="h3" v-else>learning</Text> -->
      <Icon name="close" @click="close" />
    </List>
    <Text el="p" v-if="learning != null">{{ learning.description }}</Text>
    <Accordion v-if="learning != null">
      <List el="column-between xsm" style="align-items: center">
        <List el="column xsm" style="align-items: center">
          <!-- <Icon name="add" /> -->
          <Text el="h5">More details</Text>
        </List>
        <span class="material-icons"></span>
      </List>
      <List el="xsm">
        <Text el="p">Uploaded by: {{ learning.uploadedBy.displayName }}</Text>
        <Text el="p">ID: {{ learning.id }}</Text>
        <Text el="p">Date: {{ learning.date }}</Text>
        <Text el="p">Attached files: {{ learning.attachedFiles.length }}</Text>
        <div class="tags" v-if="learning != null">
          <Text v-for="item in learning.tags" :key="item">{{ item }}</Text>
        </div>
      </List>
    </Accordion>
    <List el="xxsm" v-if="learning != null">
      <Link
        :to="'/file/learning/' + learning.id + '/' + item.name"
        v-for="item in learning.attachedFiles"
        :key="item"
        class="file"
      >
        <span :class="{'material-icons':true, red: item.type.split('/')[0] == 'video' || item.type == 'application/pdf', icon:true, purple: item.type.split('/')[0] == 'image'}">
          {{ assignIcon(item.type) }}
        </span>
        {{ formatFilename(item) }}
      </Link>
    </List>
    <List
      el="column-stretch xsm"
      style="margin-top: 32px"
      v-if="learning != null && $store.getters.isAdmin"
    >
      <Btn el="2ry" @click="deleteLearning(learning.id)">Delete</Btn>
      <Btn
        @click="setLearningStatus(learning.id, 'publish')"
        v-if="learning.status == 'draft'"
        >Publish</Btn
      >
      <Btn
        @click="setLearningStatus(learning.id, 'draft')"
        v-if="learning.status == 'publish'"
        >Make Draft</Btn
      >
    </List>
    <!-- <List el="column-stretch xsm">
      <Btn el="2ry">Delete</Btn>
      <Btn>Add Files</Btn>
    </List> -->
  </List>
</template>
<script>
let startsWith = require("lodash/startsWith");
let forEach = require("lodash/forEach");
import router from "../router";
import { db } from "@/firebase";
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";
import Icon from "@/components/btn/Icon.vue";

const storage = getStorage();

export default {
  components: {
    Icon,
  },
  data() {
    return {
      window: this.$store.state.main.window,
      user: this.$store.state.main.user,
      main: this.$store.state.main.layout.main,
      section: this.$store.state.main.layout.section,
      learning: null,
    };
  },
  watch: {
    "$route.params.id": function () {
      this.getLearning();
    },
  },
  methods: {
    assignIcon(type) {
      let t = type.split("/");
      let icon = "file_open";
      switch (t[0]) {
        case "image":
          icon = "image";
          break;
        case "video":
          icon = "ondemand_video";
          break;
      }
      switch (type) {
        case "application/pdf":
          icon = "picture_as_pdf";
          break;
        case "text/html":
          icon = "article";
          break;
      }
      return icon;
    },
    formatFilename(item) {
      if (startsWith(item.type, "video")) {
        let nameArr = item.name.split(".");
        nameArr.pop();
        return nameArr.join("");
      } else {
        return item.name;
      }
    },
    async getLearning() {
      let routeParams = this.$route.params.id;
      if (routeParams != null) {
        const docRef = doc(db, "learning", this.$route.params.id);
        const docSnap = await getDoc(docRef);
        let data = docSnap.data();
        data.id = docSnap.id;
        this.learning = data;
      }
    },
    close() {
      this.closeSection();
      router.push("/dashboard/learning");
    },
    closeSection() {
      this.section.collapse = true;
    },
    async setLearningStatus(id, status) {
      const docRef = doc(db, "learning", id);
      // Set the "capital" field of the city 'DC'
      await updateDoc(docRef, {
        status: status,
      });
      this.closeSection();
      this.getLearning();
    },
    async deleteLearning(id) {
      let x = this;
      var deletePrompt = prompt('To confirm please type "delete"');

      if (deletePrompt != null && deletePrompt == "delete") {
        forEach(x.learning.attachedFiles, function (item) {
          // Create a reference to the file to delete
          const path = `learning/${id}/${item.name}`;
          const fileRef = ref(storage, path);

          // Delete the file
          deleteObject(fileRef)
            .then(() => {
              // File deleted successfully
              console.log(`Deleted "${path}"`);
              deleteDoc(doc(db, "learning", id));
              x.$store.commit("learning/delete", id);
              x.close();
            })
            .catch(() => {
              // Uh-oh, an error occurred!
              alert(
                `Unable to delete file "${path}". Please notify your webmaster.`
              );
              console.log(
                `Unable to delete file "${path}". Please notify your webmaster.`
              );
            });
        });
      }
    },
  },
  mounted() {
    this.getLearning();
  },
};
</script>
<style lang="scss" scoped>
.icon{
  color: var(--color-sky);
}
.red{
  color:darkred;
}
.purple{
  color:var(--color-aurora);
}
.tags {
  display: flex;
  flex-wrap: wrap;
}
.tags > * {
  font-size: 12px;
  margin: 3px;
  padding: 3px 13px;
  /* border: 1px solid var(--color-7); */
  justify-self: flex-start;
  border-radius: 100px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.03);
  user-select: none;
  color: var(--color-65);
  -webkit-text-fill-color: currentColor;
}
.tags > .active {
  color: var(--color-white);
  background-color: var(--color-cloud);
}
.file {
  padding: 4px 8px;
  border-radius: 5px;
  display: grid;
  // background-color: var(--color-sky-5);
  grid-auto-flow: column;
  align-items: center;
  text-decoration: none;
  grid-gap: var(--space-xxsm);
  transition: 0.2s;
  justify-content: flex-start;
  font-weight: bold;
  --color:#222 !important;
}
.file:hover {
  background-color: var(--color-sky-5);
}
</style>
